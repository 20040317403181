import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { templateAnimate } from '@syncfusion/ej2-charts';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { Messpunkt } from 'src/app/core/BackendApi/messpunkt.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { DteRow } from 'src/app/core/DteufcAggregatorServiceApi/dterow.complex';
import { SectionState } from 'src/app/core/Enums';
import { DteMath, IDtePwhParams, IDteResult } from 'src/app/core/math/DteMath';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { ValueFormatterService } from 'src/app/core/ValueFormatterService';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';

@Component({
    selector: 'app-dte',
    templateUrl: './dte.component.html',
    styleUrls: ['./dte.component.scss'],
    providers: [provideContext("Dte")],
})
export class DteComponent implements OnInit {

    stepText = this.langService.getString('PanelStepText_T');
    stepTitle = this.langService.getString('PanelHeadline_T');
    stepBack = this.langService.getString('PanelStepBackLabel_T');
    stepDescription = this.langService.getString('PanelDescription_D');
    temperaturWert;


    // Graph Variables
    public primaryYAxis: object;
    public primaryXAxis: object;
    public chartDataBedarfsvolumenstrom: Messpunkt[];
    public chartDataPWH60: Messpunkt[];
    public chartDataBetriebspunkt: Messpunkt[];
    public tooltipBetriebspunkt: Object = { enable: true, header: 'Betriebspunkt' };
    public tooltipformatBetriebspunkt: string = this.langService.getString('TooltipformatBetriebspunkt_T_Html', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'), this.langService.getString('#Generic.Unit.DegreeCelsius_T'));
    public legendSettings: Object;
    public animation: object;
    public palette: string[];

    // General Project/Table Variables
    private readonly pwh: number = DteMath.pwh;
    private selectedProject: ProjectEntity;
    public bedarfsvolumenstrom : number;
    private dteRows: DteRow[];

    // Sidebar Values
    minPWHTemperatur : number;
    druckverlustStrecke : number;
    druckverlustGesamt : number;
    druckverlustHpa: number;

    // Bindings/Calculation Values
    public betriebspunkt: Messpunkt;
    public selectedDTE : "40" | "70" | "100";
    public vorlauftemperatur: number = 65;
    public vorlauftemperaturSliderMin: number = 45;
    public benoetigteVorlauftemperatur: number;
    private recommendedDTE: DteRow;
    private DTEAvailable: IDteResult[];
    public loadedDteIsInvalid: boolean;
    private bedarfsvolumenstromEffective: number;

    // Constructors/Initializers
    constructor(
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private DataService: ProjectDataService,
        private langService: LangService,
        private formatter: ValueFormatterService,
        private aggregatorService: AggregatorDataService,
        private dteMath: DteMath,
        private valueFormatterService: ValueFormatterService
    ) { }

    ngOnInit(): void {
        // load current project
        this.selectedProject = this.activatedRoute.snapshot.data['project'];
        if (this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin || !(this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin > 0)) {
            this.bedarfsvolumenstrom = this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin;
        } else {
            this.bedarfsvolumenstrom = 55; // DEBUG value
        }

        if (this.selectedProject.ProjectValues.Dte && this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput) {
            this.vorlauftemperatur = this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput;
        }

        // Set initial values
        this.dteRows = this.aggregatorService.aggregatorData.Tables.DteRows;
        this.minPWHTemperatur = this.pwh;
        this.animation = { enable: false };
        this.palette = ["#5181e8", "#5181e8", "#000000"];
        this.legendSettings = { visible: true, alignment: 'Center', shapeWidth: 40 };



        // Execute initial calculations
        this.onChangeVorlauftemperatur();

        // Override recommended dte value with loaded when if available
        if(this.selectedProject.ProjectValues.Dte){
            this.DTEAvailable = this.dteMath.dtesFromBedarfsvolumenstrom(this.bedarfsvolumenstrom, { dteRows: this.dteRows })
            this.loadedDteIsInvalid = !this.dteMath.dtesFromBedarfsvolumenstrom(this.bedarfsvolumenstrom, { dteRows: this.dteRows })
    	        .some(d => d.dte == this.selectedProject.ProjectValues.Dte.Ergebnis.toString());

            if (!this.loadedDteIsInvalid) {
                this.selectDte(this.selectedProject.ProjectValues.Dte.Ergebnis.toString() as "40" | "70" | "100", false);
            } else {
                this.selectRecommendedDte();
                this.calculateValues();
            }
        }
        else {
            this.loadedDteIsInvalid = false;
            this.selectRecommendedDte();
            this.calculateValues();
        }
    }

    // Internal Methods
    private calculateValues() : void {
        // Get current dte info
        let dteInfo = this.dteRows.find(d => d.Dte == this.selectedDTE);
        if(dteInfo){
            // Get graph axis config
            this.primaryYAxis = {
                minimum: dteInfo.DiagramStartVorlauftemperatur, maximum: dteInfo.DiagramEndVorlauftemperatur, interval: dteInfo.DiagramStepVorlauftemperaturLMin,
                    title: this.langService.getString('LeistungsdiagrammYAxis_T', undefined, this.langService.getString('#Generic.Unit.DegreeCelsius_T'))
            };
            this.primaryXAxis = {
                minimum: dteInfo.DiagramStartBedarfsvolumenstromLMin, maximum: dteInfo.DiagramEndBedarfsvolumenstromLMin, interval: dteInfo.DiagramStepBedarfsvolumenstromLMin,
                    title: this.langService.getString('LeistungsdiagrammXAxis_T', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'))
            };

            // Get sidebar values
            this.druckverlustHpa = this.dteMath.dteDruckverlust(this.bedarfsvolumenstrom, dteInfo.KvsSecondary);
            this.druckverlustStrecke = this.dteMath.dteDruckverlust(this.bedarfsvolumenstrom, dteInfo.KvsCirculationFlow);
            this.druckverlustGesamt = this.druckverlustHpa;

            // Get graph values
            var yValues = this.dteMath.volauftemperaturenMesspunkteFromDte(dteInfo, this.pwh);
            this.chartDataPWH60 = yValues.map(v => ({ Vorlauftemperatur: v, Bedarfsvolumenstrom: this.dteMath.dteVsFromBedarfstemp(dteInfo, v, this.pwh) }));
            this.createBetriebspunkte();
        } else {
            // Reset sidebar values
            this.druckverlustGesamt = 0;
            this.druckverlustStrecke = 0;
            this.druckverlustHpa = 0;
        }

        // Set dte button classes
        switch (this.selectedDTE) {
            case '40': this.selectDte40(); break;
            case '70': this.selectDte70(); break;
            case '100': this.selectDte100(); break;
            default: this.selectDteNone(); break;
        }
    }
    private createBetriebspunkte() : void {
        // note that some of these values MUST be rounded to two digits, or the graphical representation will be invalid
        let dteInfo = this.dteRows.find(d => d.Dte == this.selectedDTE);
        const x = Math.round(this.dteMath.dteVsFromBedarfstemp(dteInfo, Number(this.vorlauftemperatur), this.pwh) * 100) / 100;
        const y = Math.round(this.vorlauftemperatur * 100) / 100;
        this.betriebspunkt = { Bedarfsvolumenstrom: x, Vorlauftemperatur: y }
        this.chartDataBetriebspunkt = [
            { Bedarfsvolumenstrom: 0, Vorlauftemperatur: y },
            { Bedarfsvolumenstrom: x, Vorlauftemperatur: y },
            { Bedarfsvolumenstrom: x, Vorlauftemperatur: 0 }
        ];
    }
    private deselectDTE() : void {
        this.resetGraph();
        this.selectedDTE = null;
        this.vorlauftemperatur = 0;
    }
    private resetGraph() : void {
        this.primaryYAxis = {
            minimum: 50, maximum: 90, interval: 5,
            title: this.langService.getString('LeistungsdiagrammYAxis_T', undefined, this.langService.getString('#Generic.Unit.DegreeCelsius_T'))
        };
        this.primaryXAxis = {
            minimum: 40, maximum: 120, interval: 5,
            title: this.langService.getString('LeistungsdiagrammXAxis_T', undefined, this.langService.getString('#Generic.Unit.LiterPerMinute_T'))
        };
        this.chartDataPWH60 = [{ Vorlauftemperatur: 0, Bedarfsvolumenstrom: 0 }];
        this.chartDataBetriebspunkt = [{ Bedarfsvolumenstrom: 0, Vorlauftemperatur: 0 }]
    }

    // Button/Click Events
    public selectDte(type: "40" | "70" | "100", autoOptimizeSettings: boolean): void {
        if(this.selectedDTE != type){
            this.loadedDteIsInvalid = false;
            this.selectedDTE = type;

            const dteRow: DteRow = this.dteRows.find(d => d.Dte === this.selectedDTE);
            this.benoetigteVorlauftemperatur = this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                dteRow,
                bedarfstemperaturPwh: 60,
                bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
            });

            if (autoOptimizeSettings)
                this.setOptimalDteValues();

            this.vorlauftemperaturSliderMin = Math.ceil(this.benoetigteVorlauftemperatur);
            this.calculateValues();

            const dteParams: IDtePwhParams = this.dteMath.getDtePwhParams(dteRow, 60);
            this.bedarfsvolumenstromEffective = this.bedarfsvolumenstrom;
            this.chartDataBedarfsvolumenstrom = [
                { Vorlauftemperatur: 50, Bedarfsvolumenstrom: this.bedarfsvolumenstromEffective },
                { Vorlauftemperatur: 90, Bedarfsvolumenstrom: this.bedarfsvolumenstromEffective }
            ];
        }


    }
    public goBack() : void {
        this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]);
    }
    public navigateNext() : void {
        if(this.selectedProject.ProjectValues.Dte){
            if(this.selectedProject.SectionStates.Dte == SectionState.Set && this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput === this.vorlauftemperatur && this.selectedProject.ProjectValues.Dte.Ergebnis == Number(this.selectedDTE)){
                this.selectedProject.SectionStates.Dte = SectionState.Set;
                this.selectedProject.ProjectValues.Dte.Vorlauftemperatur = this.vorlauftemperatur;
                this.DataService.saveCurrentProject()
                    .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
                return;
            }
            this.selectedProject.ProjectValues.Dte.Vorlauftemperatur = this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
                bedarfstemperaturPwh: this.pwh,
                dteRow: this.dteRows.find(d => d.Dte == this.selectedDTE)
            });
            this.selectedProject.ProjectValues.Dte.VorlauftemperaturInput = this.vorlauftemperatur;
            this.selectedProject.ProjectValues.Dte.PwhTemperatur = this.pwh;
            this.selectedProject.ProjectValues.Dte.DruckverlustHpa = this.druckverlustHpa;
            this.selectedProject.ProjectValues.Dte.DruckverlustZirkulationsstreckeHpa = this.druckverlustStrecke;
            this.selectedProject.ProjectValues.Dte.DruckverlustGesamtHpa = this.druckverlustGesamt;
            this.selectedProject.ProjectValues.Dte.Ergebnis = Number(this.selectedDTE);
            this.selectedProject.ProjectValues.Dte.MesspunktePwh60 = this.chartDataPWH60;
            this.selectedProject.ProjectValues.Dte.BetriebspunktPwh60 = this.betriebspunkt;
            this.selectedProject.ProjectValues.Dte.GraphBedarfsvolumenstromEffektiv = this.bedarfsvolumenstromEffective;
        }
        else {
            this.selectedProject.ProjectValues.Dte = {
                Vorlauftemperatur: this.dteMath.benoetigteVorlauftemperaturFromBedarfsvolumenstrom({
                    bedarfsvolumenstromLMin: this.bedarfsvolumenstrom,
                    bedarfstemperaturPwh: this.pwh,
                    dteRow: this.dteRows.find(d => d.Dte == this.selectedDTE)
                }),
                VorlauftemperaturInput: this.vorlauftemperatur,
                PwhTemperatur: this.pwh,
                DruckverlustHpa: this.druckverlustHpa,
                DruckverlustZirkulationsstreckeHpa: this.druckverlustStrecke,
                DruckverlustGesamtHpa: this.druckverlustGesamt,
                Ergebnis: Number(this.selectedDTE),
                MesspunktePwh60: this.chartDataPWH60,
                BetriebspunktPwh60: this.betriebspunkt,
                GraphBedarfsvolumenstromEffektiv: this.bedarfsvolumenstromEffective
            };
        }

        this.selectedProject.ProjectValues.BedarfsvolumenstromEffektivLMin = this.selectedProject.ProjectValues.BedarfsvolumenstromErgebnisLMin;
        this.selectedProject.ProjectValues.VorlauftemperaturEffektiv = this.selectedProject.ProjectValues.Dte.Vorlauftemperatur;
        this.selectedProject.ProjectValues.BedarfstemperaturGrad = this.pwh;

        this.selectedProject.SectionStates.Dte = SectionState.Set;

        if (this.selectedProject.SectionStates.Rohrauslegung === SectionState.Set) {
            this.selectedProject.SectionStates.Rohrauslegung = SectionState.Check;
        }

        if(this.selectedProject.SectionStates.Ufc == SectionState.Set)
            this.selectedProject.SectionStates.Ufc = SectionState.Check;


        if(this.selectedProject.SectionStates.BuildingTypeWarmwasserStates){
            this.selectedProject.SectionStates.BuildingTypeWarmwasserStates.forEach(b => {
                if(b.Value == SectionState.Set){
                    b.Value = SectionState.Check;
                }
            });
        }

        if (this.selectedProject.SectionStates.Energiespeicher === SectionState.Set) {
            this.selectedProject.SectionStates.Energiespeicher = SectionState.Check;
        }

        if(this.vorlauftemperatur > this.selectedProject.ProjectValues.Dte.Vorlauftemperatur){
            this.selectedProject.ProjectValues.Dte.Vorlauftemperatur = this.vorlauftemperatur;
        }

        this.DataService.saveCurrentProject()
            .then(_ => this.router.navigate([`/project-view/${this.selectedProject.ProjectId}`]));
    }

    // Change Events
    public onChangeVorlauftemperatur(): void {
        // Recalculate
        this.calculateValues();
    }

    private selectRecommendedDte(): void
    {
        // Get available DTEs for configuration
        this.DTEAvailable = this.dteMath.dtesFromBedarfsvolumenstrom(this.bedarfsvolumenstrom, { dteRows: this.dteRows })
        if (this.DTEAvailable && this.DTEAvailable.length > 0) {
            // Get recommended DTE
            this.recommendedDTE = this.dteMath.recommendedDte({
                bedarfstemperaturPwh: this.pwh,
                bedarfsvolumenStromLMin: this.bedarfsvolumenstrom,
                dteRows: this.dteRows
            })

            // Select recommended DTE if available
            if (this.recommendedDTE) {
                this.selectDte(this.recommendedDTE.Dte as "40" | "70" | "100", true)
            }
            else {
                this.deselectDTE();
            }
        } else {
            this.deselectDTE();
        }
    }

    private setOptimalDteValues(): void
    {
        this.vorlauftemperatur = Math.ceil(this.benoetigteVorlauftemperatur);
    }

    // Validation Methods
    public isDteDisabled(dte: string) : boolean {
        if (this.DTEAvailable && this.DTEAvailable.length > 0) {
            return !this.DTEAvailable.some(x => x.dte == dte)
        }
        return true;
    }

    // Value Formatters
    public getDteName() : string {
        return this.formatter.formatDteName(this.selectedDTE);
    }
    public getVorlauftemperatur() : string {
        return this.formatter.formatNumber(this.vorlauftemperatur, 'DegreeCelsius')
    }
    public getBedarfsvolumenstrom() : string {
        return this.formatter.formatNumber(Number(this.bedarfsvolumenstrom), 'LiterPerMinute', {maximumFractionDigits: 0})
    }
    public getVorlaufErgebnis(){
        return this.formatter.formatNumber(Math.ceil(Number(this.benoetigteVorlauftemperatur)), 'DegreeCelsius', { maximumFractionDigits: 0 });
    }
    public getMinPwhTemperatur() : string {
        return this.valueFormatterService.formatNumber(this.minPWHTemperatur, 'DegreeCelsius');
    }
    public getDruckverlustStrecke() : string {
        return this.valueFormatterService.formatNumber(this.druckverlustStrecke, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getDruckverlustGesamt() : string {
        return this.valueFormatterService.formatNumber(this.druckverlustGesamt, 'HektopascalInBar', { maximumFractionDigits: 0 });
    }
    public getBedarfsvolumenstromPwh60(): string{
        return this.valueFormatterService.formatNumber(60, 'DegreeCelsius');
    }
    public getDte40Btn(): string{
        return this.valueFormatterService.formatDteName('40')
    }
    public getDte70Btn(): string{
        return this.valueFormatterService.formatDteName('70')
    }
    public getDte100Btn(): string{
        return this.valueFormatterService.formatDteName('100')
    }
    public getPwh60(): string{
        return this.formatter.formatNumber(60, 'DegreeCelsius')
    }


    // DTE Button Style Setter
    private selectDte40() : void {
        var dte40 = document.getElementById('dte40'); //TODO, wenn wieder funktioniert
        var dte70 = document.getElementById('dte70'); //mit ViewChild und switch-case?
        var dte100 = document.getElementById('dte100');
        dte40.classList.add('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte100.classList.remove('dteButtonFocused');
    }
    private selectDte70() : void {
        var dte70 = document.getElementById('dte70');
        var dte40 = document.getElementById('dte40');
        var dte100 = document.getElementById('dte100');
        dte70.classList.add('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
        dte100.classList.remove('dteButtonFocused');
    }
    private selectDte100() : void {
        var dte100 = document.getElementById('dte100');
        var dte40 = document.getElementById('dte40');
        var dte70 = document.getElementById('dte70');
        dte100.classList.add('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
    }
    private selectDteNone() : void {
        var dte100 = document.getElementById('dte100');
        var dte40 = document.getElementById('dte40');
        var dte70 = document.getElementById('dte70');
        dte100.classList.remove('dteButtonFocused');
        dte70.classList.remove('dteButtonFocused');
        dte40.classList.remove('dteButtonFocused');
    }
}
